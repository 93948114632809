<template>
  <section class="hero-section" id="services">
    <div class="overlay">
      <div class="content">
        <h1 class="title">Tu máš priestor<br />pre svoj rozvoj!</h1>
        <h1 class="title2">Príjmame aj multisport karty. Čakáme ťa</h1>
        <div class="multisport"></div>
        <div class="membership-options">
          <div class="option">
            <h2>Jednorázový vstup</h2>
            <ul>
              <li>Bez akéhokoľvek vypĺňania, jednoducho prídeš a cvičíš. Vyskúšaj si naše moderné vybavenie a motivujúcu atmosféru s jednorazovým vstupom. Príď, zaži to na vlastnej koži a rozhodni sa, či sa chceš stať súčasťou našej komunity!</li>
              <li class="price" style="bottom: 25px;">4,90€</li>
            </ul>
          </div>
          <div class="option">
            <h2>Členstvá</h2>
            <ul>
              <li><b>Mesačné členstvo:</b> Neobmedzený prístup k modernému vybaveniu a cvičeniam bez viazanosti.<br />
                  <b>Polročné členstvo:</b> Pravidelné cvičenie za výhodnejšiu cenu na 6 mesiacov.<br />
                  <b>Ročné členstvo:</b> Najvýhodnejšia ponuka s neobmedzeným prístupom na celý rok.

                </li>
                <li class="pricing"><a href="cennik.pdf" target="_blank">Cenník</a></li>
            </ul>
          </div>
        </div>
          <div class="membership-options">
          <div class="option">
            <h2>Masáže</h2>
            <ul>
              <li>
                  Masáž je skvelý spôsob, ako uvoľniť telo, zmierniť stres a podporiť celkovú pohodu. Stimuluje krvný obeh, zásobuje svaly kyslíkom a živinami, uvoľňuje stuhnuté svaly, zmierňuje bolesti a zlepšuje pohyblivosť.
                  <br/>Napíš nám a dohodni si termín:
                  <b>mff.fitko@gmail.com</b>
                  </li>
              <li class="pricing"><a href="cennik.pdf" target="_blank">Cenník</a></li>
            </ul>
          </div>
          <div class="option">
            <h2>Skupinové cvičenia</h2>
            <ul>
              <li>
                Skupinové cvičenia podporujú kondíciu aj pohodu. Vyskúšajte <b>Tabatu</b> na spaľovanie kalórií, <b>BodyForming</b> na tvarovanie postavy, <b>Kruhový tréning</b> pre kondíciu, <b>Strečing +50</b> na flexibilitu alebo <b>Terapia hudbou</b> pre relax a harmóniu. Pridajte sa a užívajte si pohyb!
              </li>
              <li class="pricing"><a href="https://mffitness.reservio.com" target="_blank">rezervácie</a> | <a href="cennik.pdf" target="_blank">Cenník</a></li>
            </ul>
          </div>
        </div>
        <a href="/newclient/"><button class="join-button">Pridaj sa k nám online, bez záväzkov</button></a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MembershipSection',
};
</script>

<style scoped>
.hero-section {
  position: relative;
  height: 1135px;
  background: url('@/assets/bg.jpeg') no-repeat center center/cover;
}
.multisport {
  width: 120px;
  height: 76px;
  background: url('@/assets/multisport.png');
  background-size: 120px 76px;
  margin: 0 auto;
  margin-top: -50px;
  margin-bottom: 20px;
}
.m {
  font-size: 14px;
  margin: 0;
  margin-left: 65px;
}

a {
    color: white;
    text-decoration: none;
}

a:hover {
  color: #fff200;
}

.pricing {
  margin-bottom: 10px;
  font-size: 54px;
  font-weight: 100;
  line-height: 24px;
  position: absolute;
  right: 25px;
  bottom: 25px;
}

.pricing a {
    font-size: 24px;
    text-transform: uppercase;
    text-align: right;
}

.overlay {
  position: absolute;
  height: 1135px;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Dark overlay for better text visibility */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.content {
  color: #fff;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 40px; /* Reduced top gap */
}

.title {
  margin-top: -30px;
  font-size: 48px;
  margin-bottom: 0px; /* Reduced bottom margin */
  color: #fff200;
  text-transform: uppercase;
  line-height: 60px;
}

.title2 {
  font-size: 32px;
  margin-bottom: 60px; /* Reduced bottom margin */
  text-transform: uppercase;
  font-weight: 600;
}
.price {
  font-size: 42px !important;
  font-weight: 800;
  float: right;

  position: absolute;
  bottom: 0px;
  right: 25px;
}

.membership-options {
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.option {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 480px;
  padding: 40px;
  padding-bottom: 60px;
  text-align: left;
}

.option h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #fff200; /* Bright yellow color */
}

.option ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.option ul li {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
}

.join-button {
  background-color: #fff200; /* Bright yellow */
  color: #1e1e1e;
  border: none;
  padding: 15px 0;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
  max-width: 640px; /* This matches the combined width of the two options plus the gap */
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.join-button:hover {
  background-color: #e6bf1a;
}

.option:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Responsive styles */
@media (max-width: 768px) {
  .hero-section {
    margin-top: 80px;
    height: 2162px;
  }

  .overlay {
    height: 2162px;
  }

  .title {
    font-size: 36px;
  }

  .membership-options {
    flex-direction: column;
    align-items: center;
  }

  .option {
    width: 100%;
    max-width: 300px;
  }

  .join-button {
    width: 360px; /* Full width on mobile */
  }
  .price {
    font-size: 42px !important;
    font-weight: 800;
    float: right;

    position: relative;
    bottom: 0px;
    right: 25px;
    padding-top: 40px;
  }
  .pricing {
    font-size: 42px !important;
    font-weight: 800;
    float: right;

    position: relative;
    bottom: 25px;
    right: 25px;
    padding-top: 25px;
  }
  .m {
    font-size: 14px;
    margin: 0;
    margin-left: 65px;
  }
  .option {
    padding-bottom: 0px;
    padding-right: 0px;
  }
}
</style>
